import React from 'react';
import './projectsheader.css';

const ProjectsHeader = ({header}) => {
    return(
        <div className="EBDevelopers__projectsheader section__padding" id="projects">
            <div className="EBDevelopers__projectsheader-heading">
                <h1 className="gradient__text">{header}</h1>
            </div>
        </div>
    )
}

export default ProjectsHeader