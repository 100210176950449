import React from 'react';
import {Link} from 'react-router-dom';
import {ProjectType} from '../../components';
import {Feature} from '../../components';
import {fifegardenposter3} from '../../../../assets/currentprojects';
import {nifemivilla4} from '../../../../assets/pastprojects';
import futureprojects from '../../../../assets/futureprojects.jpg';
import './projecttypes.css';

const ProjectTypes = () => {
    return(
        <div className="EBDevelopers__projecttypes section__padding" id="projecttypes">
            <div className="EBDevelopers__projecttypes-heading">
                <h1 className="gradient__text"> Explore our projects</h1>
                <Feature text="Transparency"/>
            </div>
            

            <div className="EBDevelopers__projecttypes-container">
                <div className="EBDevelopers__projecttypes-container_groupA">
                    <Link to="/currentprojects"><ProjectType imgUrl={fifegardenposter3}  title=" Current"/></Link>
                </div>
                <div className="EBDevelopers__projecttypes-container_groupB">
                    <Link to="/futureprojects"><ProjectType imgUrl={futureprojects}  title=" Future"/></Link>
                    <Link to="/pastprojects"><ProjectType imgUrl={nifemivilla4}  title=" Past"/></Link>
                </div>
            </div>
        </div>
    )
}

export default ProjectTypes