import React from 'react';
import {Feature} from '../../../home/components';
import './features.css';



const Features = ({title, featuresData})=> {
    return(
        <div className="EBDevelopers__features section__padding" id="features">
            <div className="EBDevelopers__features-heading">
                <h1 className="gradient__text">{title}</h1>
                <p>Enter description about life at fife garden</p>
            </div>
            <div className="EBDevelopers__features-container">
                {featuresData.map((item, index)=>(
                    <Feature title={item.title} text={item.text} key={item.title + index}/>

                ))}
            </div>
        </div>

    )
}

export default Features