import React from 'react';
import './projectcomplex.css';

const ProjectComplex = ({imgUrl, title}) =>{
    return(
        <div className="EBDevelopers__projectcomplexs-container_projectcomplex">
                <div className="EBDevelopers__projectcomplexs-container_projectcomplex-image">
                    <img src={imgUrl} alt="Project Poster"/>
                </div>

                <div className="EBDevelopers__projectcomplexs-container_projectcomplex-content">
                    <div>
                        <h3>{title}</h3>
                    </div>
                </div>
        </div>
    )
}

export default ProjectComplex