import React from 'react';
import './header.css';
import {Link} from 'react-router-dom';
import people from '../../../../assets/people.png';
import poolhouse from '../../../../assets/poolhouse.png';

const Header = () => {

    return(
        <div className="EBDevelopers__header section__padding" id="home">
            <div className="EBDevelopers__header-content">
                <h1 className="gradient__text"> The Sky is only the Beginning</h1>
                <p>Building your foundation, Building your environment</p>

                <div className="EBDevelopers__header-content__input">
                    <input type="email" placeholder="Your Email Address"/>
                  <Link to="/constructionpage"><button type="button">Register Now</button></Link>
                </div>

                {/* <div className="EBDevelopers__header-content__people">
                    <img src={people} alt="people"/>
                    <p> 100 residents have reserved lots</p>
                </div> */}
            </div> 

            <div className="EBDevelopers__header-image">
                <img src={poolhouse} alt="ai"/>
            </div>
            
        </div>
    )
}

export default Header