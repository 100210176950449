import React from 'react';
import{GrHostMaintenance} from 'react-icons/gr'
import {Navbar, Footer} from '../../components'
import './underconstruction.css';

const UnderConstruction = () => {


    return(
        <div className="EBDevelopers__underconstruction" >
            <div className="gradient__bg">
                    <Navbar/>
                </div>
                <div className="EBDevelopers__underconstruction-container">
                
                    <div className="EBDevelopers__underconstruction-container__header  section__padding">
                        <h1 className="gradient__text">This page is undergoing maintenance; Please come back another time.</h1>
                    </div>

                    <div className="maintenanceStyles" >
                    <GrHostMaintenance color= "white" size={400} />
                    </div>
                </div>
                <Footer/>
            
        </div>
    )
}

export default UnderConstruction