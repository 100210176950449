import React from 'react';
import {Features,ProjectSlider} from '../'
import './projectdescription.css';

const ProjectDescription = ({descriptionTitle, sliderPhotos, featuresData}) => {
    return (
        <div className="EBDevelopers__ProjectDescription section__padding">
            <Features title={descriptionTitle} featuresData={featuresData}/>
            <ProjectSlider sliderPhotos={sliderPhotos}/>
        </div>
    )
}

export default ProjectDescription