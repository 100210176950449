import React from 'react';
import {Navbar, Footer} from '../../components';
import {ProjectHeader, ProjectDescription, ProjectProperties} from './containers';
import './project.css';

const Project = ({project}) => {
    return(
        <div className="EBDevelopers__project ">
            <div className="gradient__bg">
            <Navbar/>
            <ProjectHeader coverImage ={project.coverImage} address={project.address} phoneNumber={project.phoneNumber} unitType1={project.unitType1} unitType2={project.unitType2} projectHeaderTitle={project.projectHeaderTitle}/>
            </div>
            <ProjectDescription descriptionTitle={project.descriptionTitle} sliderPhotos={project.sliderPhotos} featuresData={project.featuresData}/>
            <div className="gradient__bg">
            <ProjectProperties price={project.price} unitType1={project.unitType1} unitType2={project.unitType2} unitsRemaining={project.unitsRemaining} squareFootage={project.squareFootage}/>
            </div>
            <Footer/>
        </div>
    )
}

export default Project