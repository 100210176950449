import React from 'react';
import {FaChevronLeft, FaChevronRight } from 'react-icons/fa'
import {BsDot} from 'react-icons/bs';
import {useState} from 'react';
import './projectslide.css';

const ProjectSlide = ({slides}) => {
    const [currentIndex, setCurrentIndex] = useState(0);

    const slideStyles = {
        backgroundImage: `url(${slides[currentIndex].imag})`,
    };

    const goToNext = () =>{
        const isFirstSlide = (currentIndex === 0);
        const newIndex = isFirstSlide ? slides.length-1 : currentIndex-1;
        setCurrentIndex(newIndex);
    }

    const goToPrevious = () =>{
        const isLastSlide = (currentIndex === (slides.length-1));
        const newIndex = isLastSlide ? 0 : currentIndex+1;
        setCurrentIndex(newIndex);
    }

    const goToSlide = slideIndex => {
        setCurrentIndex(slideIndex);
    }

    return(
        <div className="EBDevelopers__projectslide">
            <div className="EBDevelopers__projectslide-leftchevron"  onClick={goToPrevious}><FaChevronLeft color= "#787f98" size={55}/></div>
            <div className="EBDevelopers__projectslide-slides" style={slideStyles}></div>
            <div className="EBDevelopers__projectslide-rightchevron" onClick={goToNext}><FaChevronRight color= "#787f98" size={55}/></div>
            <div className="EBDevelopers__projectslide-dots">
                {slides.map((slide, slideIndex) => (
                    <div key={slideIndex} onClick={() => goToSlide(slideIndex)}><BsDot color= "#787f98" size={45}/></div>
                ))}
            </div>
        </div> 
    )
}

export default ProjectSlide