import React from 'react';
import './projecttype.css';

const ProjectType = ({imgUrl, date, title}) =>{
    return(
        <div className="EBDevelopers__projecttypes-container_projecttype">
                <div className="EBDevelopers__projecttypes-container_projecttype-image">
                    <img src={imgUrl} alt="blog"/>
                </div>

                <div className="EBDevelopers__projecttypes-container_projecttype-content">
                    <div>
                        <p>{date}</p>
                        <h3>{title}</h3>
                        {/* <p>Read More</p> */}
                    </div>
                </div>
        </div>
    )
}

export default ProjectType