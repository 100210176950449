import React from 'react';
import {Navbar, Footer} from '../../components';
import {Projects} from '../../containers';
import { ProjectsHeader } from '../home/components';
import './currentprojects.css';
import { fifegardenposter2 } from '../../assets/currentprojects';

const CurrentProjects = () => {

    const projectsData = [
        {
            projectLink: "/fifegarden",
            coverImage: fifegardenposter2,
            title: "Fife Garden"
        }
    ];

    return (
        <div className="EBDevelopers__currentprojects gradient__bg" id="currentprojects">
            <div className="gradient__bg">
            <Navbar/>
            <ProjectsHeader header="Projects Under Development"/>
            <Projects projectsData={projectsData}/>
            <Footer/>
            </div>
            
        </div>
    )
}

export default CurrentProjects