import {fifegardenhouseimage1, 
    fifegardenhouseimage2, 
    fifegardenhouseimage3, 
    fifegardenhouseimage4,
    fifegardenhouseimage5, 
    fifegardenhouseimage6, 
    fifegardenhouseimage7, 
    fifegardenhouseimage8,
    fifegardenposter,
    fifegardenposter2,
    fifegardenposter3} from '../../assets/currentprojects';


const fifeGarden = {
    coverImage: fifegardenposter, 
    projectHeaderTitle: "Fife Garden",
    address: "Igbosanyin, Off Akure/Oba-Ile Road, Akure, Akure North Local Govt.Area, Ondo State", 
    phoneNumber: "+234-(803) 320 7022", 
    unitType1: "3 Bed bungalows & BQ", 
    unitType2: "2 Bed bungalows & BQ",

    descriptionTitle:"Life at Fife Garden",

    featuresData: [
        {
            title: 'Shopping Mall',
            text: 'Enter text here'
        },
    
        {
            title: 'Children\'s Play Ground',
            text: 'Enter text here'
        },
    
        {
            title: 'Adult Play Ground',
            text: 'Enter text here'
        },
    
        {
            title: 'Nature Park',
            text: 'Enter text here'
        },
    
        {
            title: 'Perimeter Fencing',
            text: 'Enter text here'
        },
    
        {
            title: '24/7 Power Supply',
            text: 'Enter text here'
        },
    
        {
            title: 'CCTV and Access Control',
            text: 'Enter text here'
        },
    
        {
            title: 'Road Network with drainage',
            text: 'Enter text here'
        },
    
        {
            title: 'Security',
            text: 'Enter text here'
        },
    
    ],

    sliderPhotos: [
        {imag: fifegardenhouseimage1, title:"house1"},
        {imag: fifegardenhouseimage2, title:"house2"},
        {imag: fifegardenhouseimage3, title:"house3"},
        {imag: fifegardenhouseimage4, title:"house4"},
        {imag: fifegardenhouseimage5, title:"house5"},
        {imag: fifegardenhouseimage6, title:"house6"},
        {imag: fifegardenhouseimage7, title:"house7"},
        {imag: fifegardenhouseimage8, title:"house8"},
    ],

    price: "45M+",
    unitsRemaining: "Limited", 
    squareFootage: "",

};

export {fifeGarden}