import React from 'react';
import {Link} from 'react-router-dom';
import './projectheader.css';
import fifegardenposter from '../../../../assets/currentprojects/fifegardenposter.jpeg'

const ProjectHeader = ({coverImage, address, phoneNumber, unitType1, unitType2, projectHeaderTitle}) => {
    return(
        <div className= "EBDevelopers__projectheader section__padding ">
            <div className="EBDevelopers__projectheader-image">
                <img src={coverImage} alt="project header"/>
            </div>
            
            <div className="EBDevelopers__projectheader-content">
                <div className="EBDevelopers__projectheader-content__btns">
                   <Link to="/constructionpage"> <p>Contact Us</p></Link>
                   <Link to="/constructionpage"><p>Book Tour</p></Link>
                   <Link to="/constructionpage"><p>Apply Online</p></Link>
                    </div>
                
                <h1 className="gradient__text">{projectHeaderTitle}</h1>
                <p>{address}</p>
                <p id="pnumber">{phoneNumber}</p>

                <div className="EBDevelopers__projectheader-content__btn">
                <a href="#availabilityandpricing"><button type="button">View Availability</button></a>
                </div>

                <div className="EBDevelopers__projectheader-content__availability">
                    <p>{unitType1}</p> 
                    
                    <p>{unitType2}</p>
                </div>
                
            </div>
            
        </div>
    )
}

export default ProjectHeader