import React from 'react';
import {Navbar, Footer} from '../../components';
import { ProjectsHeader} from '../home/components';
import { Projects } from '../../containers';
import './futureprojects.css'

const FutureProjects = () => {

   const projectsData = [
        {
            projectLink: "/constructionpage",
            coverImage: "",
            title: "Feranmi Estate"
        },
        {
            projectLink: "/constructionpage",
            coverImage: "",
            title: "Keith Castle"
        },
    ];

    return(
        <div className="EBDevelopers__futureprojects gradient__bg" id="futureprojects">
            <div className="gradient__bg">
            <Navbar/>
            <ProjectsHeader header="Upcoming Projects"/>
            
            {(projectsData.length == 0) ? 
                <div className="EBDevelopers__futureprojects-content">
                    <h1>No projects to show at the time</h1>
                </div> 
            :<Projects projectsData={projectsData}/> }
                       
            <Footer/>
            </div>
            
        </div>
    )
}

export default FutureProjects