import React from 'react';
import './brand.css';
import {GiCctvCamera} from 'react-icons/gi';
import {GrEmergency} from 'react-icons/gr';
import {AiOutlineShopping} from 'react-icons/ai';
import {IoSchoolSharp} from 'react-icons/io5';
import {MdLocalAirport} from 'react-icons/md';

//each brand will be a review, reviews will be dynamically added from the database
const Brand = () => {
    return(
        <div className="EBDevelopers__brand section__padding">

            <div className="EBDevelopers__brand-title">
                <h1 className="gradient__text">Conveniences</h1>
            </div>
            <div className="EBDevelopers__brand-container">
                <div>
                    <GiCctvCamera color="#787f98" size={55}/>
                    <p>Security</p>
                </div>
                <div>
                    <GrEmergency color= "#787f98" size={55}/>
                    <p>Emergency services</p>
                </div>
                <div>
                    <AiOutlineShopping color="#787f98" size={55}/>
                    <p>Near by shopping locations</p>
                </div>
                <div>
                    <IoSchoolSharp color="#787f98" size={55}/>
                    <p>Near by schools</p>
                </div>
                <div>
                    <MdLocalAirport color="#787f98" size={45}/>
                    <p>Near by airport</p>
                </div>
            </div>
        </div>
    )
}

export default Brand;