import React from 'react';
import {Link} from 'react-router-dom';
import {ProjectComplex} from '../';
import './projects.css';

const Projects = ({projectsData}) => {
    return(
        <div className="EBDevelopers__projects section__padding" id="projects">
            <div className="EBDevelopers__projects-container">
                <div className="EBDevelopers__projects-container_groupA">
                    
                    
                </div>
                <div className="EBDevelopers__projects-container_groupB">
                    {projectsData.map((dataItem,index)=>(
                        <Link to={dataItem.projectLink} key={index}><ProjectComplex imgUrl={dataItem.coverImage} title={dataItem.title}/></Link>
                    ))}
                    
                    {/* <ProjectType imgUrl={projects02} date= "1 January 1970" title="Fife Garden"/>
                    <ProjectType imgUrl={projects03} date= "1January 1970" title=" Enter title here"/>
                    <ProjectType imgUrl={projects05} date= "1 January 1970" title=" Enter title here"/>
                    <ProjectType imgUrl={projects04} date= "1 January 1970" title=" Enter title here"/> */}
                </div>
            </div>
        </div>
    )
}

export default Projects