import{ nifemivilla1,
        nifemivilla2,
        nifemivilla3,
        nifemivilla4,
        nifemivilla5,
        nifemivilla6,
        nifemivilla7,
        nifemivilla8,
        nifemivilla9,
        nifemivilla10,
        nifemivilla11,
        nifemivilla12,
        nifemivilla13,
        nifemivilla14,
        nifemivilla15,
        nifemivilla16,
        nifemivilla17,
        nifemivilla18,
        nifemivilla19,
        nifemivilla20,
        nifemivilla21} from '../../assets/pastprojects';


const nifemiVilla = {
    coverImage: nifemivilla4, 
    projectHeaderTitle: "Nifemi Villa",
    address: "Oluwalowa Abraham Street (Akure, Ondo State, Nigeria)", 
    phoneNumber: "+234-(803) 320 7022", 
    unitType1: "3 Bed", 
    unitType2: "2 Bed",

    descriptionTitle:"Life at Fife Nife Villa",

    featuresData: [
        {
            title: 'Shopping Mall',
            text: 'Enter text here'
        },
    
        {
            title: 'Children\'s Play Ground',
            text: 'Enter text here'
        },
    
        {
            title: 'Adult Play Ground',
            text: 'Enter text here'
        },
    
        {
            title: 'Nature Park',
            text: 'Enter text here'
        },
    
        {
            title: 'Perimeter Fencing',
            text: 'Enter text here'
        },
    
        {
            title: '24/7 Power Supply',
            text: 'Enter text here'
        },
    
        {
            title: 'CCTV and Access Control',
            text: 'Enter text here'
        },
    
        {
            title: 'Road Network with drainage',
            text: 'Enter text here'
        },
    
        {
            title: 'Security',
            text: 'Enter text here'
        },
    
    ],

    sliderPhotos: [
        {imag: nifemivilla1, title:"house1"},
        {imag: nifemivilla2, title:"house2"},
        {imag: nifemivilla3, title:"house3"},
        {imag: nifemivilla4, title:"house4"},
        {imag: nifemivilla5, title:"house5"},
        {imag: nifemivilla6, title:"house6"},
        {imag: nifemivilla7, title:"house7"},
        {imag: nifemivilla8, title:"house8"},
    ],

    price: "18M+", 
    unitsRemaining: "5", 
    squareFootage: "",

};

export {nifemiVilla}