import React from 'react';
import {Footer, Navbar,} from './components';
import { Header, Reviews, WhatProjects, Features, Possibility, ProjectTypes} from './pages/home/containers';
import {EmailSubscription, CTA, Brand} from './pages/home/components';
import './App.css';
import { CurrentProjects } from './pages';
const App = ()=> {

	return (
		<div className="App">
			<div className ="gradient__bg">
                <Navbar/>
                <Header />
            </div>

            <Brand/>
            <WhatProjects/>
            <ProjectTypes/>
            <Possibility/>
            <CTA/>
            {/* <Reviews/> */}
            <Footer/>
		</div>
	)
}

export default App
