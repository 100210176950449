import React from 'react';
// import possibilityImage from '../../../../assets/possibility.png';
import './possibility.css';

const Possibility = () => {
    return(
        <div className="EBDevelopers__possibility section__padding" id="possibility">
            <div className="EBDevelopers__possibility-image">
                {/* <img src ={possibilityImage} alt="possibility"/> */}
            </div>
            <div className="EBDevelopers__possibility-content">
                <h4>Register and get started with our</h4>
                <h1 className="gradient__text">Flexible Payment Plan</h1>
                <p>Enter text here</p>
                <h4>register to get started</h4>
            </div>

        </div>
    )
}

export default Possibility