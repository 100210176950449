import React from 'react';
import './whatprojects.css';
import Feature from '../../components/feature/Feature';

const WhatProjects = () => {
    return(
        <div className= "EBDevelopers__whatprojects section__margin" id="ourcompany">
            <div className="EBDevelopers__whatprojects-feature">
                <Feature title="Who are we?" text="ARE YOU LIVING ABROAD AND LOOKING FOR A RELIABLE AND TRANSPARENT REAL ESTATE COMPANY  IN NIGERIA TO BUY GENUINE LANDED PROPERTIES  FROM OR REAL HOMES."/>
            </div>

            <div className="EBDevelopers__whatprojects-heading">
                <h1 className="gradient__text">
                    Look no further
                </h1>
               <a href="#projecttypes"> <p>Explore our properties</p></a>
            </div>
            <div className="EBDevelopers__whatprojects-subheading">
                <Feature title="A one stop company" text= "A fantastic opportunity for you to be the  owner of your dream home has come through today with Ever Bright Developers and Real Estate Company."/>
            </div>

            <div className="EBDevelopers__whatprojects-container">
                <Feature title="Transparency" text="Enter text here"/>
                <Feature title="Accountability" text="Enter text here"/>
                <Feature title="Integrity" text="Enter text here"/>
            </div>
            
        </div>
    )
}

export default WhatProjects