import React, { useState } from 'react';
import './navbar.css'
import {Link} from 'react-router-dom';
import {RiMenu3Line, RiCloseLine} from 'react-icons/ri';

// react functional component and a react fragment 
    //wrapping the menu links to make them reusable throughout the project
const Menu = () => (
    <> 
    <p><Link to="/">Home</Link></p>
    <p><a href="#footer">Contact Us</a></p>
    <p><Link to="/currentprojects">Current Projects</Link></p>
    <p><Link to="/pastprojects">Past Projects</Link></p>
    <p><Link to="/futureprojects">Future Projects</Link></p>
    <p><Link to="/">Our Company</Link></p>
    </>
)

//CSS naming convention: Block Element Modifier(BEM)
const Navbar = () => {
    const [toggleMenu, setToggleMenu] = useState(false);

    return(
        <div className = "EBDevelopers__navbar">
            
            <div className= "EBDevelopers__navbar-links">
                <div className="EBDevelopers__navbar-links_logo">
                    {/* <img src={logo} alt ="logo"/> */}
                   <Link to="/"> <h1>Ever Bright Developer & Real Estate</h1></Link>
                </div>

                <div className= "EBDevelopers__navbar-links_container">
                    <Menu/>
                </div>
            </div>
            <div className= "EBDevelopers__navbar-sign">
                <Link to="/constructionpage"><p>Sign in</p></Link>
                <Link to="/constructionpage"><button type="button">Sign up</button></Link>
            </div>
            <div className ="EBDevelopers__navbar-menu">
                {toggleMenu
                ?  <RiCloseLine color="#fff" size={27} onClick = {()=> setToggleMenu(false)}/>
                : <RiMenu3Line color="#fff" size={27} onClick = {()=> setToggleMenu(true)}/>
                    
                }
                {toggleMenu && (
                    <div className= "EBDevelopers__navbar-menu_container scale-up-center">
                        <div className="EBDevelopers__navbar-menu_container-links">
                            <Menu/>                            
                        </div>

                        <div className= "EBDevelopers__navbar-menu_container-links-sign">
                                <p>Sign in</p>
                                <button type="button">Sign up</button>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default Navbar

{/* <div className="EBDevelopers__navbar-links">
                <h2 className="logoName">Ever Bright Developer & Real Estate</h2>

                <ul className="nav-bar">
                    <li><a href="">Home</a></li>
                    <li><a href="">Contact Us</a></li>
                    <li><a href="">Gallery</a></li>
                    <li><a href="">Our Company</a></li>
                </ul> 
    </div>*/}
