import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { CurrentProjects, PastProjects, FutureProjects, Project, UnderConstruction } from './pages';
import {fifeGarden} from './pages/project/fifeGardenImports.js';
import {nifemiVilla} from './pages/project/nifemiVillaImports.js';
import {BrowserRouter, Routes, Route} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter basename={process.env.PUBLIC_URL}>
    <Routes>
      <Route path="/" element={<App />}/>
      <Route path="/currentprojects" element={<CurrentProjects/>}/>
      <Route path="/pastprojects" element={<PastProjects/>}/>
      <Route path="/futureprojects" element={<FutureProjects/>}/>
      <Route path="/fifegarden" element={<Project project={fifeGarden}/>}/>
      <Route path="/nifemivilla" element={<Project project={nifemiVilla}/>}/>
      <Route path="/constructionpage" element={<UnderConstruction/>}/>
    </Routes>
    </BrowserRouter>
  </React.StrictMode>
);