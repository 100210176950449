import React from 'react';
import{Link} from 'react-router-dom';
import './projectproperty.css';

const ProjectProperty = ({price, unitType1, unitType2, unitsRemaining, squareFootage}) => {
    return(
        <div className="EBDevelopers__projectproperty">
            <div className="EBDevelopers__projectproperty-heading">
                <h1 className="gradient__text">Our Availability and Pricing</h1>
            </div>

            <div className="EBDevelopers__projectproperty-container">
                <div className="EBDevelopers__projectproperty-container__info">
                    <p>{price}</p>
                    <p>{unitType1} / {unitType2}</p>
                    <p>{squareFootage}</p>
                    <p>Quantity Left: {unitsRemaining}</p>
                </div>
                <div className="EBDevelopers__projectproperty-container__images">
                    <p>Images Coming Soon</p>
                </div>

                <div className="EBDevelopers__projectproperty-container__cat">
                    <div className="EBDevelopers__projectproperty-container__cat-btns">
                       <Link to="/constructionpage"><p>Contact Us</p></Link>
                       <Link to="/constructionpage"><p>Book Tour</p></Link>
                       <Link to="/constructionpage"><p>Apply Online</p></Link>
                    </div>
                </div>
            </div>
        
        </div>
    )
}

export default ProjectProperty