import React from 'react';
import {Link} from 'react-router-dom';
import './cta.css';

const CTA = () => {
    return(
        <div className="EBDevelopers__cta">
            <div className="EBDevelopers__cta-content">
                <p>Please, Don't be left out, be part of this opportunity today.</p>
                <h3>Invest  with us and get your dream home from start to finish. </h3>
            </div>        
            <div className="EBDevelopers__cta-btn">
                <Link to="/constructionpage"><button type="button">Get Started</button></Link>
            </div>
        </div>
    )
}

export default CTA