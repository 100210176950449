import React from 'react';
import { nifemivilla1 } from '../../assets/pastprojects';
import {Navbar, Footer} from '../../components';
import {Projects} from '../../containers';
import './pastprojects.css';
import { ProjectsHeader } from '../home/components';

const PastProjects = () => {

    const projectsData = [
        {
            projectLink: "/nifemivilla",
            coverImage: nifemivilla1,
            title: "Nifemi Villa",
        }
    ];

    return(
        <div className="EBDevelopers__pastprojects gradient__bg" id="pastprojects">
            
            <div className="gradient__bg">
                <Navbar/>
                <ProjectsHeader header="Completed Projects"/>
                <Projects projectsData={projectsData}/>
                <Footer/>
            </div>
                    
        </div>
    )
}

export default PastProjects