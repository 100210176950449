import React from 'react';
import {ProjectProperty} from '../../components'
import './projectproperties.css';

const ProjectProperties = ({price, unitType1, unitType2, unitsRemaining, squareFootage}) => {
    return(
        <div className="EBDevelopers__projectproperties section__padding" id="availabilityandpricing">
            <div className="EBDevelopers__projectproperties">
                <ProjectProperty price={price} unitType1={unitType1} unitType2={unitType2} unitsRemaining={unitsRemaining} squareFootage={squareFootage}/>
            </div>
        </div>
    )
}

export default ProjectProperties