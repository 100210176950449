import React from 'react';
import {Link} from 'react-router-dom';
import Menu from '../navbar/Navbar';
import './footer.css';

const Footer = () => {

    return (
        <div className="EBDevelopers__footer section__padding" id="footer">
            <div className="EBDevelopers__footer-heading">
                <h1 className="gradient__text">Building homes without issues, supervision of site projects, and selling of issue free landed properties is our way of life.</h1>
            </div>
            <div className="EBDevelopers__footer-btn">
            <Link to="/constructionpage"><p>Register Here</p></Link>
            </div>

            <div className="EBDevelopers__footer-links">
                <div className="EBDevelopers__footer-links_logo">
                    {/* <img src={EBDevelopersLogo} alt="logo" /> */}
                    <h1>Ever Bright Developer & Real Estate</h1>
                </div>
                <div className="EBDevelopers__footer-links_div">
                    <h4>Links</h4>
                    <p><Link to="/">Home</Link></p>
                    <p><a href="#footer">Contact Us</a></p>
                    <p><Link to="/currentprojects">Current Projects</Link></p>
                    <p><Link to="/pastprojects">Past Projects</Link></p>
                    <p><Link to="/futureprojects">Future Projects</Link></p>
                    <p><Link to="/">Our Company</Link></p>
                    {/* <p>Overrons</p>
                    <p>Social Media</p>
                    <p>Counters</p>
                    <p>Contact</p> */}
                </div>
                <div className="EBDevelopers__footer-links_div">
                    <h4>Company</h4>
                   <Link to="/constructionpage"> <p>Terms & Conditions</p></Link>
                   <Link to="/constructionpage"> <p>Privacy Policy</p></Link>
                   <Link to="#footer"> <p>Contact</p></Link>
                </div>
                <div className="EBDevelopers__footer-links_div">
                    <h4>Get in touch</h4>
                    <p>http://www.ebdre.com</p>
                    <p>
                        +234-(803) 320 7022<br/>
                        +234-(806) 209 5201<br/>
                        +234-(901) 254 3471<br/>
                        +234-(916) 616 5896<br/>
                        +234-(901) 170 8783<br/>
                        +234-(916) 496 0173<br/>
                        +234-(915) 630 5828<br/>
                    </p>
                    <p>everbrightdevelopers @gmail.com</p>
                </div>
            </div>

            <div className="EBDevelopers__footer-copyright">
                <p>COPYRIGHT © 2023 EVER BRIGHT DEVELOPER & REAL ESTATE - ALL RIGHTS RESERVED.</p>
            </div>
        </div>
    )
}

export default Footer