import React from 'react';
import { ProjectSlide } from '../../components';
import './projectslider.css';
import {fifegardenhouseimage1, 
        fifegardenhouseimage2, 
        fifegardenhouseimage3, 
        fifegardenhouseimage4,
        fifegardenhouseimage5, 
        fifegardenhouseimage6, 
        fifegardenhouseimage7, 
        fifegardenhouseimage8} from './imports.js';


const ProjectSlider = ({sliderPhotos}) =>{

const containerStyles ={
        width:"500px",
        height: "280px",
        margin: "0 auto"
    }

    return(
        <div className="EBDevelopers__projectslider">
            <div className="EBDevelopers__projectslider-container">
                <ProjectSlide slides={sliderPhotos}/>
            </div>

        </div>
    )
}

export default ProjectSlider
